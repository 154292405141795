<template>
  <form class="mt-8 space-y-6" action="#">
    <div>
      <label for="email" :class="{'text-red-700 dark:text-red-500': v$.email.$error}" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
      <input v-model="email"
             type="email" name="email" id="email"
             :class="classInputEmail"
             class="sm:text-sm rounded-lg block w-full p-2.5"
             required>
      <p v-if="v$.email.$error" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ v$.email.$errors[0].$message }}</p>
    </div>
    <div>
      <label for="password" :class="{'text-red-700 dark:text-red-500': v$.password.$error}" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Пароль</label>
      <input v-model="password"
             type="password" name="password" id="password"
             :class="classInputPassword"
             class="sm:text-sm rounded-lg block w-full p-2.5"
             required>
      <transition-group>
        <p v-if="v$.password.$error" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ v$.password.$errors[0].$message }}</p>
      </transition-group>

    </div>
    <div class="flex items-start" v-if="false">
      <div class="flex items-center h-5"></div>
      <div class="ml-3 text-sm"></div>
      <router-link :to="{name: 'ForgotPassword'}" href="#" class="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500">Забыли пароль?</router-link>
    </div>
    <button @click.prevent="login" type="submit"
            class="py-3 px-5 w-full text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
      Войти
    </button>
    <div v-if="authStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
      {{ authStore.error }}
    </div>
  </form>

</template>

<script setup>
import {ref, computed} from "vue";
import {useAuthStore} from "../../stores/AuthStore.js";
import useVuelidate from '@vuelidate/core'
import {required, email as emailValidator, minLength} from '../../utilits/custom-validators.js'

const authStore = useAuthStore();

const email = ref('');
const password = ref('');

const rules = computed(() => ({
  email: {required, emailValidator},
  password: {required, minLength: minLength(8)},
}));
const v$ = useVuelidate(rules, {email, password})

const login = () => {

  v$.value.$validate()

  if (!v$.value.$error) {
    authStore.login(email.value, password.value)
  }

}

const defaultClass = 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500';
const errorClass = 'bg-primary-50 bg-primary-50 border-primary-300 text-primary-900 focus:ring-primary-500';

const classInputPassword = computed(() => ({
  [errorClass]: v$.value.password.$error,
  [defaultClass]: !v$.value.password.$error,
}));

const classInputEmail = computed(() => ({
  [errorClass]: v$.value.email.$error,
  [defaultClass]: !v$.value.email.$error,
}));
</script>