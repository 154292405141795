const checkRole = (role) => {
    let show = false
    let authUser = JSON.parse(localStorage.getItem('authUser'))

    if (authUser) {
        authUser.roles.forEach(function (item) {
            if (typeof role === 'string') {
                if (item.key === role) {
                    show = true;
                }
            } else {
                if (role.includes(item.key)) {
                    show = true;
                }
            }
        });
    }

    return show;
}

const scrollToElement = (targetId) =>  {
    const el = document.getElementById(targetId);

    if (el) {
        el.scrollIntoView();
    }
}

export {scrollToElement, checkRole};