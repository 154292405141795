<template>
  <div
      class="mb-2"
      :class="className"
  >

    <label for="surname"  v-html="label" v-if="label"
           :class="{'text-red-700 dark:text-red-500': isError(), 'mb-2' : !yearInput}"
           class="block font-medium text-basic-gray"></label>

    <input :value="modelValue"
           v-on:input="updateValue($event.target.value)"
           :type="type"
           :class="classInput"
           :placeholder="placeholder"
           :disabled="disabled"
           class="text-sm rounded-lg block w-full p-2.5 disabled:bg-gray-200">
  </div>

  <p v-if="isError()" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ v$.$errors[0].$message }}</p>
</template>

<script setup>

import {computed, toRefs} from "vue";

const props = defineProps({
  label: String,
  yearInput: {type: Boolean, default: false},
  placeholder: {type: String, default: ''},
  type: {type: String, default: 'text'},
  modelValue: {String, Number},
  v$: Object,
  disabled: {type: Boolean, default: false},
  className: { type: String, default: null }
})

const emit = defineEmits(['update:modelValue'])

const {v$} = toRefs(props);

function updateValue(value) {
  emit('update:modelValue', value)
  if (v$.value !== undefined) {
    v$.value.$touch();
  }
}

const isError = () => {
  return v$.value !== undefined && v$.value.$error;
}


const defaultClass = 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500';
const errorClass = 'bg-primary-50 bg-primary-50 border-primary-300 text-primary-900 focus:ring-primary-500';

const classInput = computed(() => ({
  [errorClass]: isError(),
  [defaultClass]: !isError(),
}));

</script>
