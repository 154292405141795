<template>

  <form class="mt-8 space-y-6" action="#">
    <div>
      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
      <input type="email" name="email" id="email"
             class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
             placeholder="mail@mail.ru" required>
    </div>
    <div class="flex items-start">
      <div class="flex items-center h-5"></div>
      <div class="ml-3 text-sm"></div>
    </div>
    <button type="submit"
            class="py-3 px-5 w-full text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
      Сброс пароля
    </button>
  </form>

</template>

<script setup>
</script>