import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useLogStore = defineStore('logStore', () => {
    const entities = ref([]);
    const modelOptions = ref([]);
    const pagination = ref(null);
    const error = ref(null);

    const getEntities = async (params = null, url = '/developers/logs') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    }

    const getDirectories = async (params = null) => {
        await axios.get('/developers/logs/directories')
            .then((r) => {
                modelOptions.value = r.data.data.modelOptions;
            }).catch((e) => {
                catchResponse(e)
            })
    }

    const catchResponse = (e) => {
        if (e.response.status === 422) {
            let errorMessage = [];
            for (let key in e.response.data.errors) {
                errorMessage.push(e.response.data.errors[key][0]);
            }
            error.value = errorMessage.join(' ');
        } else {
            error.value = 'Ошибка сервера. Попробуйте позже';
        }
    }

    return {
        pagination,
        modelOptions,
        entities,
        error,
        getEntities,
        getDirectories,
    }
})
