import {createI18n} from "vue-i18n";
import messages from '@intlify/vite-plugin-vue-i18n/messages'

let locale = localStorage.getItem('locale')

if (!locale) {
    locale = 'ru'
}

/* @TODO: Удалить */
locale = 'ru'

const i18n = createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'en',
    messages
})

export default i18n;