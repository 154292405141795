<template>
  <div class="grid grid-cols-1 px-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">{{ $t('common.users') }}</h1>
          </div>
          <div class="flex-shrink-0">
            <a @click.prevent="openDialogImport" href="#" class="p-2 mr-3 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100">{{ $t('common.import') }}</a>
            <router-link :to="{name: 'UserEdit', params: {id: 0}}" class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100">&plus; {{ $t('common.add') }}</router-link>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-1/2">
            <form @submit.prevent="searchUser()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">{{ $t('common.search') }}</label>
              <div class="relative w-full">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="search"
                       type="text" id="top-bar-search"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
                       :placeholder="$t('common.search')">
              </div>
            </form>
          </div>
          <div class="flex-col p-7 w-1/4">
            <input-select v-model="activeOptions"/>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input v-model="checkboxAllUsersSelected"
                               @click="selectAllCheckbox"
                               id="checkbox-all" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('users.name') }}
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('users.role') }}
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('users.active') }}
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('users.date_reg') }}
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="user of userStore.users" :key="user.id" :class="{'bg-neutral-100 opacity-50': user.isDeleted}" class="hover:bg-gray-100">
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        <input v-model="userIds" :value="user.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ user.name }}</p>
                      <p class="text-sm text-gray-500">{{ user.email }}</p>
                    </td>
                    <td class="p-4">{{ user.roles }}</td>
                    <td class="p-4 text-base font-normal text-gray-900 whitespace-nowrap">
                      <div class="flex items-center">
                        <div :class="{'bg-red-500': !user.active, 'bg-green-400': user.active}" class="h-2.5 w-2.5 rounded-full mr-2"></div>
                      </div>
                    </td>
                    <td class="p-4">{{ user.created }}</td>
                    <td class="text-right">
                      <router-link :to="{name: 'UserEdit', params: {id: user.id}}" class="py-2 px-3 text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !userStore.users.length" class="text-xl text-center p-7">{{ $t('common.notFound') }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between border-t">
          <div class="flex-col p-7 w-1/2">
            <template v-if="userIds.length">
              <div>
                <button @click.prevent="deleteUsers" v-if="!isRestoreUsers" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>{{ $t('common.delete') }}</span>
                </button>
                <button @click.prevent="restoreUsers" v-if="isRestoreUsers" class="flex px-6 py-4 bg-blue-500 rounded-md text-white hover:bg-blue-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>{{ $t('common.restore') }}</span>
                </button>
              </div>
            </template>
            <template v-else>
              <div v-if="userStore.pagination">
                <span class="text-gray-500">{{ $t('common.found') }}</span> {{ userStore.pagination.total }}
              </div>
            </template>
          </div>
        </div>

      </div>
    </div>
  </div>

  <modal v-if="showDialog" @closeDialog="closeImportDialog" :load-data="loadDataInModal">
    <template #title>{{ $t('common.import') }}</template>
    <template #body>
      <form action="#">
        <div class="grid gap-4 mb-12 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <label for="import-file" class="block font-medium mb-2">{{ $t('users.choose_file') }}</label>

            <input id="import-file" v-on:change="importFile" type="file" name="import" accept=".csv"/>
          </div>
        </div>

        <div v-if="userStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {{ userStore.error }}
        </div>

        <div class="flex items-center space-x-4">
          <button @click.prevent="importUsers" type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            {{ $t('common.save') }}
          </button>
          <button @click="closeImportDialog" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            {{ $t('common.cancel') }}
          </button>
        </div>
      </form>
    </template>
  </modal>

</template>

<script setup>
import {useUserStore} from "../../stores/UserStore.js";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch} from "vue";
import InputSelect from "../flowbite/form/inputSelect.vue";
import useVuelidate from '@vuelidate/core'
import {required, email, minLength, maxLength, selectInList, helpers, sameAs} from '../../utilits/custom-validators.js'
import Modal from "../flowbite/modal.vue";

const userStore = useUserStore();
const showDialog = ref(false);
const loadData = ref(true);
const loadDataInModal = ref(true);
const checkboxAllUsersSelected = ref(false);
const userIds = ref([]);
const search = ref("");
const userImport = ref({});
const activeOptions = ref([
  {id: -1, name: 'Все', selected: true},
  {id: 1, name: 'Активен', selected: false},
  {id: 0, name: 'Неактивен', selected: false}
]);

const isRestoreUsers = ref(false);

watch(userIds, () => {
  isRestoreUsers.value = false

  let ids = []

  userIds.value.forEach((userId) => {
    ids.push(userId)
  })

  userStore.users.forEach((user) => {
    if (ids.indexOf(user.id) !== -1 && user.isDeleted) {
      isRestoreUsers.value = true
    }
  })
})

//Первоначальные данные
userStore.getUsers().then(() => {
  loadData.value = false;
})

//Валидация
const rules = computed(() => ({
  email: {required, email},
  name: {required},
  phone: {required, minLength: minLength(10), maxLength: maxLength(11)},
  roles: {
    selectInList: helpers.withMessage('Нужно выбрать минимум одну роль', selectInList('attached'))
  },
  password: {minLength: minLength(9)},
  password_confirmation: {
    sameAsPassword: helpers.withMessage('Должно быть равно паролю', sameAs(state.password))
  },
}));

let state = reactive({
  email: "",
  name: "",
  phone: "",
  roles: [],
  password: "",
  password_confirmation: "",
});

watch(userStore, (userStore) => {
  if (userStore.user) {
    state.email = userStore.user.email;
    state.name = userStore.user.name;
    state.phone = userStore.user.phone;
    state.roles = userStore.user.roles;
    state.password = userStore.user.password;
    state.password_confirmation = userStore.user.password_confirmation;
  }
});

const v$ = useVuelidate(rules, state)

//Галочка выбрать всё
const selectAllCheckbox = () => {
  userIds.value = [];

  if (!checkboxAllUsersSelected.value) {
    userStore.users.forEach((user, index) => {
      userIds.value.push(user.id)
    });
  }
}

const deleteUsers = () => {
  userStore.deleteUsers(userIds.value).then(() => {
    userIds.value = [];
  })
}

const searchUser = () => {
  loadData.value = true;

  let activeOption = activeOptions.value.filter(item => item.selected);

  let params = {
    search: search.value,
    filter: {
      active: activeOption[0].id
    }
  }

  userStore.getUsers(params).then(() => {
    loadData.value = false;
  });

}

watch(activeOptions.value, (activeOptions) => {
  loadData.value = true;

  let activeOption = activeOptions.filter(item => item.selected);

  let params = {
    search: search.value,
    filter: {
      active: activeOption[0].id
    }
  }

  userStore.getUsers(params).then(() => {
    loadData.value = false;
  });
})

const restoreUsers = () => {
  userStore.restoreUsers(userIds.value).then(() => {
    userIds.value = [];
  })
}

const openDialogImport = () => {
  loadDataInModal.value = false
  showDialog.value = true
}

const closeImportDialog = () => {
  showDialog.value = false
}

const importFile = (e) => {
  let files = e.target.files || e.dataTransfer.files;

  if (files.length) {
    userImport.value.file = files[0]
  }
}

const importUsers = () => {
  loadDataInModal.value = true
  userStore.error = false

  let formData = new FormData()

  for (let key in userImport.value) {
    formData.append(key, userImport.value[key])
  }

  userStore.importUsers(formData).then(() => {
    loadDataInModal.value = false

    if (!userStore.error) {
      searchUser()
      closeImportDialog()
    }
  })
}
</script>