const toggleSidebar = (expend) => {

    const sidebarEl = document.getElementById('sidebar');
    const mainContentEl = document.getElementById('main-content');
    const bottomMenuEl = document.querySelector('[sidebar-bottom-menu]');

    if (expend === true) {
        sidebarEl.classList.add('lg:w-64');
        sidebarEl.classList.remove('lg:w-16');
        mainContentEl.classList.add('lg:ml-64');
        mainContentEl.classList.remove('lg:ml-16');

        document.querySelectorAll('#' + sidebarEl.getAttribute('id') + ' [sidebar-toggle-item]').forEach(sidebarToggleEl => {
            sidebarToggleEl.classList.remove('lg:hidden');
            sidebarToggleEl.classList.remove('lg:absolute');
        });

        bottomMenuEl.classList.remove('flex-col', 'space-y-4', 'p-2');
        bottomMenuEl.classList.add('space-x-4', 'p-4');
    } else {
        sidebarEl.classList.remove('lg:w-64');
        sidebarEl.classList.add('lg:w-16');
        mainContentEl.classList.remove('lg:ml-64');
        mainContentEl.classList.add('lg:ml-16');

        document.querySelectorAll('#' + sidebarEl.getAttribute('id') + ' [sidebar-toggle-item]').forEach(sidebarToggleEl => {
            sidebarToggleEl.classList.add('lg:hidden');
            sidebarToggleEl.classList.add('lg:absolute');
        });


        bottomMenuEl.classList.add('flex-col', 'space-y-4', 'p-2');
        bottomMenuEl.classList.remove('space-x-4', 'p-4');
    }
}

const toggleSidebarMobile = (expend) => {

    const sidebarEl = document.getElementById('sidebar');
    const sidebarBackdrop = document.getElementById('sidebarBackdrop');
    const toggleSidebarMobileHamburger = document.getElementById('toggleSidebarMobileHamburger');
    const toggleSidebarMobileClose = document.getElementById('toggleSidebarMobileClose');

    sidebarEl.classList.toggle('hidden');
    sidebarBackdrop.classList.toggle('hidden');
    toggleSidebarMobileHamburger.classList.toggle('hidden');
    toggleSidebarMobileClose.classList.toggle('hidden');
}

export {toggleSidebar, toggleSidebarMobile};