import {defineStore} from 'pinia'
import {ref} from 'vue';

export const useInterfaceStore = defineStore('interfaceStore', () => {

    const valueStorage = localStorage.getItem('sidebarExpanded') === 'true';

    const sidebarExpanded = ref(valueStorage);

    const toggleSidebar = () => {
        sidebarExpanded.value = !sidebarExpanded.value;
        localStorage.setItem('sidebarExpanded', String(sidebarExpanded.value));
    }

    return {
        toggleSidebar,
        sidebarExpanded
    }
})