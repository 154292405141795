<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div v-if="entityStore.entity">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ entityStore.error }}
                        </div>

                        <div v-if="isEntitySaved" class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                          {{ $t('common.documentSaved') }}
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-3">
                          <div class="sm:col-span-2">
                            <template v-if="canEditAttribute('name')">
                              <template v-if="(!entityStore.entity.id || isEditName)">
                                <input-text v-model="entityStore.entity.name" :label="$t('document.documentName') + ':'"/>
                                <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                              </template>
                              <template v-else>
                                <span class="text-3xl font-medium mr-4">{{ entityStore.entity.name }}</span>
                                <button type="button" @click.prevent="isEditName.value = true">
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.586 1.58616C10.7705 1.39514 10.9912 1.24278 11.2352 1.13796C11.4792 1.03314 11.7416 0.977969 12.0072 0.975661C12.2728 0.973354 12.5361 1.02396 12.7819 1.12452C13.0277 1.22508 13.251 1.37359 13.4388 1.56137C13.6266 1.74916 13.7751 1.97246 13.8756 2.21825C13.9762 2.46405 14.0268 2.72741 14.0245 2.99296C14.0222 3.25852 13.967 3.52096 13.8622 3.76497C13.7574 4.00898 13.605 4.22967 13.414 4.41416L12.621 5.20716L9.793 2.37916L10.586 1.58616ZM8.379 3.79316L0 12.1722V15.0002H2.828L11.208 6.62116L8.378 3.79316H8.379Z" fill="#111928"/>
                                  </svg>
                                </button>
                              </template>
                            </template>
                            <template v-else>
                              <span class="text-3xl font-medium mr-4">{{ entityStore.entity.name }}</span>
                            </template>

                            <div class="mt-2 text-sm" v-if="entityStore.entity.id">{{ entityStore.entity.user.name }}</div>
                          </div>
                          <div class="sm:col-span-1" v-if="entityStore.entity.id">
                            <div class="text-right text-sm">
                              <p class="mb-2"><span class="text-gray-500">{{ $t('document.created') }}:</span> {{ moment(entityStore.entity.created_at).format('DD.MM.YYYY') }}</p>
                              <p class="mb-2"><span class="text-gray-500">{{ $t('document.updated') }}:</span> {{ moment(entityStore.entity.updated_at).format('DD.MM.YYYY') }}</p>
                              <p class="mb-2" v-if="checkRole('admin') || checkRole('engineer')">
                                <router-link :to="{name: 'UserLog', query: {object: 'App\\Models\\Document', id: entityStore.entity.id}}" target="_blank" class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">{{ $t('common.toLogs') }}</router-link>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2" v-if="entityStore.entity.status">
                          <div class="sm:col-span-2">
                            <div class="inline-block px-4 py-1 rounded-xl mr-4 whitespace-nowrap opacity-100 cursor-default" :class="['bg-status-' + entityStore.entity.status]">
                              <span v-if="entityStore.entity.status === 6">{{ statusListAll.find(item => item.id === entityStore.entity.status).process }}</span>
                              <span v-else>{{ statusListAll.find(item => item.id === entityStore.entity.status).name }}</span>
                            </div>
                          </div>
                        </div>

                        <hr class="mb-4">

                        <table class="mb-4" v-if="(!canEditAttribute('developer_id') && entityStore.entity.developer)">
                          <tr v-if="entityStore.entity.developer && !checkRole('developer')">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.assigned') }}:</td>
                            <td class="pb-3 text-sm">{{ entityStore.entity.developer.name }}</td>
                          </tr>
                          <tr v-if="!checkRole('developer') && entityStore.entity.assigned">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.assignedManager') }}:</td>
                            <td class="pb-3 text-sm">{{ entityStore.entity.assigned.name }}</td>
                          </tr>
                        </table>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2" v-if="canEditAttribute('type')" v-show="typeListAllowed.length > 1">
                            <input-select-lite v-model="entityStore.entity.type" :options="typeListAllowed" :label="$t('document.type') + ':'"/>
                            <p v-if="v$.type.$error" class="mt-2 text-sm text-red-600"> {{ v$.type.$errors[0].$message }} </p>
                          </div>

                          <template v-if="canEditAttribute('developers') && !checkRole('developer')">
                            <div class="sm:col-span-1">
                              <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.developerType') }}:</label>
                              <Multiselect v-model="entityStore.entity.draft_developer_type_id" @change="filterDraftDevelopersByType" :options="developerTypes" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                            </div>
                            <div class="sm:col-span-1">
                              <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.developer') }}:</label>
                              <Multiselect v-model="entityStore.entity.draft_developers" @select="chooseDraftDevelopers" :options="filteredDevelopers" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                              <p v-if="v$.draft_developers.$error" class="mb-2 mt-2 text-sm text-red-600"> {{ v$.draft_developers.$errors[0].$message }} </p>
                            </div>
                          </template>
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                v-model="entityStore.entity.description"
                                :label="$t('document.description') + ':'"
                                :readonly="(!canEditAttribute('description'))"/>
                          </div>
                        </div>
                        <div
                            style="border: 1px solid #919191; border-radius: 15px; padding: 10px 15px; margin-bottom: 25px;"
                            v-for="(fileGroupBlocks, index) in entityStore.entity.fileGroups"
                        >
                          <div style="margin-bottom: 10px; padding: 10px 0 10px 0;">
                            <span style="background: aliceblue; padding: 10px 15px; border-radius: 15px;">
                              <input
                                  @change="handleChangeFileGroup(index)"
                                  v-if="statusList.filter(item => item.id === entityStore.entity.status).length && checkRole('engineer')"
                                  :id="'doc-type-files-block-all-' + index"
                                  v-model="fileGroupBlocks.is_agreed"
                                  :value="fileGroupBlocks.is_agreed"
                                  type="checkbox"
                                  class="w-4 h-4 bg-gray-50 mr-2 rounded border-gray-300 focus:ring-3 checked:bg-primary focus:ring-primary-300"
                              >
                              <input
                                  @change="handleChangeFileGroup(index)"
                                  v-else-if="statusList.filter(item => item.id !== entityStore.entity.status).length && !checkRole('developer')"
                                  :id="'doc-type-files-block-all-' + index"
                                  v-model="fileGroupBlocks.is_agreed"
                                  :value="fileGroupBlocks.is_agreed"
                                  type="checkbox"
                                  class="w-4 h-4 bg-gray-50 mr-2 rounded border-gray-300 focus:ring-3 checked:bg-primary focus:ring-primary-300"
                              >
                              <label
                                  :for="'doc-type-files-block-all-' + index"
                                  class="font-medium">
                                {{fileGroupBlocks.groupName}}
                              </label>
                            </span>
                          </div>
                          <div>
                            <div v-for="(fileBlock, indexFileBlock) in fileGroupBlocks.data">
                              <div class="grid gap-4 mb-4 sm:grid-cols-2 p-4" :class="{'bg-[#effbe3] border-[#effbe3]': entityStore.entity.doc_types_agreed.includes(fileBlock.value.doc_type), 'border': true}">
                                <div class="sm:col-span-1">
                                  <div class="flex items-center mb-2">
                                    <input
                                        :disabled="fileBlock.value.files.length === 0"
                                        @change="handleChangeFileBlock(index, indexFileBlock, fileBlock.value.doc_type)"
                                        :id="'doc-type-files-' + fileBlock.value.doc_type"
                                        v-model="entityStore.entity.doc_types_agreed"
                                        :value="fileBlock.value.doc_type"
                                        v-if="statusList.filter(item => item.id === entityStore.entity.status).length && checkRole('engineer')"
                                        type="checkbox"
                                        class="w-4 h-4 bg-gray-50 mr-2 rounded border-gray-300 focus:ring-3 checked:bg-primary focus:ring-primary-300">
                                    <input
                                        :disabled="fileBlock.value.files.length === 0"
                                        @change="handleChangeFileBlock(index, indexFileBlock, fileBlock.value.doc_type)"
                                        :id="'doc-type-files-' + fileBlock.value.doc_type"
                                        v-model="entityStore.entity.doc_types_agreed"
                                        :value="fileBlock.value.doc_type"
                                        v-else-if="statusList.filter(item => item.id !== entityStore.entity.status).length && !checkRole('developer')"
                                        type="checkbox"
                                        class="w-4 h-4 bg-gray-50 mr-2 rounded border-gray-300 focus:ring-3 checked:bg-primary focus:ring-primary-300">
                                    <label :for="'doc-type-files-' + fileBlock.value.doc_type" class="block font-medium text-basic-gray">{{ $t('document.files') }} «{{ documentTypeListAll.find(documentType => documentType.id === fileBlock.value.doc_type)?.name }}»:</label>
                                  </div>

                                  <div v-if="fileBlock.value.files.length" class="mt-4">
                                    <div v-for="file in fileBlock.value.files" class="mb-3">
                                      <div class="flex">
                                        <a :href="file.src" target="_blank" class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4 hover:text-blue-600" :title="file.original_name">{{ file.original_name }}<div class="text-xs">{{ moment(file.created_at).format('DD.MM.YYYY HH:mm') }}</div></a>
                                        <span class="text-xs text-gray-400 mr-2">{{ humanFileSize(file.size) }}</span>
                                        <button @click.prevent="deleteFile(file.id)" type="button" class="hover:opacity-60 h-5" v-if="canEditAttribute('file_delete')">
                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="sm:col-span-1" v-if="!entityStore.entity.doc_types_agreed.includes(fileBlock.value.doc_type) && (canEditAttribute('file_upload') || engineerDocTypes.includes(fileBlock.value.doc_type))">
                                  <div class="sm:col-span-2">
                                    <drop-file v-model="fileBlock.value.newFiles"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p v-if="v$.files.$error" class="mb-2 mt-2 text-sm text-red-600"> {{ v$.files.$errors[0].$message }} </p>
                      </form>
                    </div>
                    <div class="flex justify-between mt-3 border-b ml-7">
                      <div>
                        <div class="flex flex-wrap">
                          <div
                              class="flex items-center mb-4"
                              v-for="option in statusList.filter(item => item.id !== entityStore.entity.status)"
                          >
                            <button
                                :disabled="checkAllFileAgreed(option.id)"
                                @click.prevent="saveEntity(option.id)"
                                class="inline-block px-4 py-1 rounded-xl mr-4 opacity-100 hover:opacity-80"
                                :class="['bg-operation-' + option.id, { 'cursor-pointer whitespace-nowrap bnt-process-disabled': checkAllFileAgreed(option.id)}]">
                              <span>{{ option.process }}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-if="checkRole('engineer') && route.params.id > 0">
                        <div class="flex flex-wrap">
                          <div class="flex items-center mb-4">
                            <label @click.prevent="saveEntity(entityStore.entity.status)" class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80" :class="['bg-operation-' + 2]">
                              <span>Сохранить</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!checkRole('developer') && entityStore.entity.fileArchives.length" class="group-archives pl-7 pb-7 pr-7 pt-2">
                      <h2 style="padding: 10px 15px; text-align: center; display: flex; justify-content: center; align-items: center;">
                        <span class="cursor-pointer" @click="isShowArchive=!isShowArchive">Архивные файлы</span>
                        <button
                            style="border-radius: 50%; background: aliceblue; margin-left: 5px;"
                            @click="isShowArchive=!isShowArchive"
                            type="button"
                            class="hover:opacity-60 h-5">
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

                            <path v-if="!isShowArchive" d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="#0F0F0F"/>
                            <path v-else d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill="#0F0F0F"/>
                          </svg>
                        </button>
                      </h2>
                      <div
                          v-if="isShowArchive"
                          style="border: 1px solid #919191; border-radius: 15px; padding: 10px 15px; margin-bottom: 25px;" v-for="(fileGroupBlocks) in entityStore.entity.fileArchives">
                        <div style="margin-bottom: 10px; padding: 10px 0 10px 0;">
                            <span style="background: aliceblue; padding: 10px 15px; border-radius: 15px;">
                              <label class="font-medium">{{fileGroupBlocks.groupName}}</label>
                            </span>
                        </div>
                        <div>
                          <div v-for="fileBlock in fileGroupBlocks.data" class="border-b">
                            <div class="grid gap-4 mb-4 sm:grid-cols-2 p-4">
                              <div class="sm:col-span-1">
                                <div class="flex items-center mb-2">
                                  <label :for="'doc-type-deleted-files-' + fileBlock.value.doc_type" class="block font-medium text-basic-gray">{{ $t('document.files') }} «{{ documentTypeListAll.find(documentType => documentType.id === fileBlock.value.doc_type)?.name }}»:</label>
                                </div>

                                <div v-if="fileBlock.value.files.length" class="mt-4">
                                  <div v-for="file in fileBlock.value.files" class="mb-3">
                                    <div class="flex">
                                      <a :href="file.src" target="_blank" class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4 hover:text-blue-600" :title="file.original_name">{{ file.original_name }}<div class="text-xs">{{ moment(file.created_at).format('DD.MM.YYYY HH:mm') }}</div></a>
                                      <span class="text-xs text-gray-400 mr-2">{{ humanFileSize(file.size) }}</span>
                                      <button @click.prevent="deleteFileRestore(file.id)" type="button" class="hover:opacity-60 h-5" v-if="canEditAttribute('file_delete')">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M4.52185 7H7C7.55229 7 8 7.44772 8 8C8 8.55229 7.55228 9 7 9H3C1.89543 9 1 8.10457 1 7V3C1 2.44772 1.44772 2 2 2C2.55228 2 3 2.44772 3 3V5.6754C4.26953 3.8688 6.06062 2.47676 8.14852 1.69631C10.6633 0.756291 13.435 0.768419 15.9415 1.73041C18.448 2.69239 20.5161 4.53782 21.7562 6.91897C22.9963 9.30013 23.3228 12.0526 22.6741 14.6578C22.0254 17.263 20.4464 19.541 18.2345 21.0626C16.0226 22.5842 13.3306 23.2444 10.6657 22.9188C8.00083 22.5931 5.54702 21.3041 3.76664 19.2946C2.20818 17.5356 1.25993 15.3309 1.04625 13.0078C0.995657 12.4579 1.45216 12.0088 2.00445 12.0084C2.55673 12.0079 3.00351 12.4566 3.06526 13.0055C3.27138 14.8374 4.03712 16.5706 5.27027 17.9625C6.7255 19.605 8.73118 20.6586 10.9094 20.9247C13.0876 21.1909 15.288 20.6513 17.0959 19.4075C18.9039 18.1638 20.1945 16.3018 20.7247 14.1724C21.2549 12.043 20.9881 9.79319 19.9745 7.8469C18.9608 5.90061 17.2704 4.3922 15.2217 3.6059C13.173 2.8196 10.9074 2.80968 8.8519 3.57803C7.11008 4.22911 5.62099 5.40094 4.57993 6.92229C4.56156 6.94914 4.54217 6.97505 4.52185 7Z" fill="#0F0F0F"/>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 mb-4 xl:mb-2" v-if="entityStore.entity.id || entityStore.entity.messages.length">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center p-7 border-b border-gray-200">
            <div class="text-3xl font-medium">
              {{ $t('document.remarksTitle') }}
            </div>
          </div>
          <div class="p-7 pt-4">
            <div class="flex-1 justify-between flex flex-col h-[50vh]">
              <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                <div class="chat-message" v-for="message in entityStore.entity.messages">
                  <div class="flex items-end" :class="{'justify-end': !message.is_answer}">
                    <div class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                      <div>
                        <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none" :class="{'bg-gray-300 text-gray-600': message.is_answer, 'bg-blue-600 text-white': !message.is_answer}">
                          <div class="text-xs">
                            {{ message.user.name }}
                          </div>
                          <div class="text-xs">
                            {{ moment(message.created_at).format('DD.MM.YYYY HH:mm') }}
                          </div>
                          <div class="text-sm mt-1">
                            {{ message.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0" v-if="entityStore.entity.id && canEditAttribute('remarks')">
                <div class="relative flex">
                  <textarea
                      :placeholder="$t('chat.write_message')"
                      v-model="entityStore.entity.remarks"
                      rows="5"
                      class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRegistryStore} from "../../stores/RegistryStore";
import {checkRole} from "../../utilits/functions";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch, onUpdated} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {helpers, required} from '../../utilits/custom-validators.js'
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../flowbite/form/inputTextarea.vue";
import DropFile from "../flowbite/form/dropFile.vue";
import moment from "moment";
import Multiselect from '@vueform/multiselect'
import i18n from "../../i18n.js";

const entityStore = useRegistryStore()
entityStore.error = null

const isEditName = ref(false)
const loadData = ref(true)
const isEntitySaved = ref(false)
const isShowArchive = ref(false);

const engineerDocTypes = ref([])

const statusList = ref([])
const statusListAll = ref([])
const typeList = ref([])
const typeListAllowed = ref([])
const documentTypeList = ref([])
const documentTypeListAll = ref([])
const changeStatusList = ref({})
const createStatusList = ref({})
const developers = ref([])
const filteredDevelopers = ref([])
const developerTypes = ref([])
const docTypesAgreed = ref([])

// Первоначальные данные
const route = useRoute()
const router = useRouter()

const checkAllFileAgreed = (statusId) => {
  if (statusId !== 6)
    return false;

  const isAllAgreed = entityStore.entity.fileGroups.every(fileGroup => fileGroup.data.every(fileBlock => fileBlock.value.is_agreed));

  return !(checkRole('engineer') && isAllAgreed);
}

const handleChangeFileGroup = (index) => {
  const docTypeGroup = entityStore.entity.fileGroups[index];
  if (!docTypeGroup) return;

  const newIsAgreed = docTypeGroup.is_agreed;

  entityStore.entity.fileGroups[index].data.map(fileBlock => {
    fileBlock.value.is_agreed = newIsAgreed;
    const findDoc = entityStore.entity.doc_types_agreed.find(valueDocId => valueDocId === fileBlock.value.doc_type);

    if (!findDoc && newIsAgreed) {
      entityStore.entity.doc_types_agreed.push(fileBlock.value.doc_type);
    }
    else if (!newIsAgreed) {
      entityStore.entity.doc_types_agreed = entityStore.entity.doc_types_agreed.filter(valueDocId => valueDocId !== fileBlock.value.doc_type);
    }

    return fileBlock;
  });
};

const handleChangeFileBlock = (index, indexFileBlock, docTypesAgreed) => {
  entityStore.entity.fileGroups[index].data[indexFileBlock].value.doc_type = docTypesAgreed;
  entityStore.entity.fileGroups[index].data[indexFileBlock].value.is_agreed = !entityStore.entity.fileGroups[index].data[indexFileBlock].value.is_agreed;

  const counterFileBlockAgreed = entityStore.entity.fileGroups[index].data.filter(fileBlock => fileBlock.value.is_agreed).length;
  entityStore.entity.fileGroups[index].is_agreed = counterFileBlockAgreed === entityStore.entity.fileGroups[index].data.length;
}

entityStore.getDirectories().then(() => {
  statusList.value = [...statusList.value, ...entityStore.statusListAll]
  statusListAll.value = [...statusListAll.value, ...entityStore.statusListAll]
  documentTypeList.value = [...documentTypeList.value, ...entityStore.documentTypeList]
  documentTypeListAll.value = [...documentTypeListAll.value, ...entityStore.documentTypeListAll]
  typeList.value = [...typeList.value, ...entityStore.typeList]
  typeListAllowed.value = [...typeListAllowed.value, ...entityStore.typeListAllowed]
  changeStatusList.value = entityStore.changeStatusList
  createStatusList.value = entityStore.createStatusList
  developerTypes.value = entityStore.developerTypes

  developers.value.push({
    id: -1,
    name: i18n.global.t('document.chooseAll'),
    type_id: 0
  })
  developers.value = [...developers.value, ...entityStore.developers]

  filteredDevelopers.value = developers.value

  if (route.params.id > 0) {
    entityStore.getEntityById(route.params.id).then(() => {
      loadData.value = false

      setAvailableStatusList()

      if (checkRole('developer')) {
        entityStore.entity.doc_types = documentTypeList.value.map(documentType => documentType.id)
      }

      if (checkRole('engineer')) {
        engineerDocTypes.value = documentTypeList.value.filter(documentType => documentType.status_id === entityStore.entity.status).map(documentType => documentType.id)

        engineerDocTypes.value.forEach((id) => {
          entityStore.entity.doc_types.push(id)
        })
      }

      docTypesAgreed.value = entityStore.entity.doc_types_agreed
    })
  } else {
    if (!checkRole('developer')) {
      router.push({name: 'Dashboard'})
    }

    entityStore.setEmptyEntity().then(() => {
      loadData.value = false

      statusList.value = statusList.value.filter(status => createStatusList.value.includes(status.id))

      entityStore.entity.status = statusList.value[0]?.id
      entityStore.entity.type = typeListAllowed.value[0]?.id

      if (checkRole('developer')) {
        entityStore.entity.doc_types = documentTypeList.value.map(documentType => documentType.id)
      }
    })
  }
})

onUpdated(() => {
  setChatScroll()
})

// Валидация
const developerIdRule = !checkRole('developer') ? {
  developerRequired: helpers.withMessage(i18n.global.t('common.validation.chooseExponent'), () => {
    return !canEditAttribute('developers') || entityStore.entity.draft_developers.length
  })
} : {}

const filesRule = {
  filesRule: helpers.withMessage(i18n.global.t('common.validation.uploadFiles'), () => {
    if (canEditAttribute('file_upload') && checkRole('engineer')) {
      return true;
    }
    return !entityStore.entity.files.filter((files) => {
      return !files.files.length && !files.newFiles.length
    }).length
  })
}

const docTypesRule = {
  developerRequired: helpers.withMessage(i18n.global.t('common.validation.required'), () => {
    if (!canEditAttribute('doc_types') || entityStore.entity.type === 2) {
      return true
    }

    return entityStore.entity.doc_types.length
  })
}

const rules = computed(() => ({
  name: {required},
  type: {required},
  doc_types: docTypesRule,
  status: {required},
  draft_developers: developerIdRule,
  files: filesRule,
}));

let state = reactive({
  name: '',
  type: '',
  doc_types: [],
  status: '',
  developer_id: '',
  description: '',
  remarks: '',
  deadline: '',
  files: [],
  fileGroups: [],
  developers: [],
});

const createGroupByDocTypeGroup = (files) => {
  let items = [];

  files.forEach((file) => {
    const docTypeGroupValue = entityStore.documentTypeListAll.find(docTypeGroup => docTypeGroup.id === file.doc_type)?.docTypeGroup;

    const docTypeGroupId = docTypeGroupValue?.id || 0;
    const docTypeGroupName = docTypeGroupValue?.name || 'Общие';

    let group = items.find(item => item.groupName === docTypeGroupName);

    if (!group) {
      group = {
        groupName: docTypeGroupName,
        data: [],
        is_agreed: false,
      };
      items.push(group);
    }

    // Добавляем информацию о файле в массив data
    group.data.push({
      idDocGroup: docTypeGroupId,
      groupName: docTypeGroupName,
      value: file,
    });
  });

  const newFileGroups = Object.values(items);

  if (JSON.stringify(entityStore.entity.fileGroups) !== JSON.stringify(newFileGroups)) {
    entityStore.entity.fileGroups = newFileGroups;
  }
};

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.type = entityStore.entity.type;
    state.doc_types = entityStore.entity.doc_types;
    state.status = entityStore.entity.status;
    state.developer_id = entityStore.entity.developer_id;
    state.description	= entityStore.entity.description;
    state.remarks	= entityStore.entity.remarks;
    state.deadline	= entityStore.entity.deadline;
    state.files	= entityStore.entity.files;
    state.fileGroups = entityStore.entity.fileGroups;

    if (entityStore.entity.doc_types !== undefined) {
      createFileBlocks();
    }
  }
})

const v$ = useVuelidate(rules, state)

// Работа с данными
const createFileBlocks = () => {
  entityStore.entity.doc_types.forEach((doc_type) => {
    if (!entityStore.entity.files.find(item => item.doc_type === doc_type)) {
      entityStore.entity.files.push({
        doc_type: doc_type,
        files: [],
        newFiles: [],
      });
    }
  })

  entityStore.entity.files.forEach((files, index) => {
    if (!entityStore.entity.doc_types.includes(files.doc_type) && !files.files.length) {
      entityStore.entity.files.splice(index, 1)
    }
  });

  if (!entityStore.entity.fileGroups.length) {
    createGroupByDocTypeGroup(entityStore.entity.files);
  }
}

const saveEntity = (newStatus) => {
  entityStore.error = null

  entityStore.entity.files = entityStore.transformDataStoreToRequest(entityStore.entity);

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true

    let isNew = entityStore.entity.id === undefined

    entityStore.entity.new_status = newStatus

    entityStore.storeEntity(entityStore.entity).then(() => {
      if (!entityStore.error && isNew) {
        router.push({name: 'RegistryEdit', params: {id: entityStore.entity.id}})
      }

      loadData.value = false

      if (!entityStore.error) {
        setAvailableStatusList()

        isEntitySaved.value = true

        setTimeout(() => {
          isEntitySaved.value = false
        }, 4000)
      }
    })

    v$.value.$reset()
  }
}

const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

const deleteFile = (fileId) => {
  if (confirm('Вы действительно хотите удалить файл?')) {
    entityStore.deleteFile(fileId).then(() => {
    });
  }
}

const deleteFileRestore = (fileId) => {
  if (confirm('Вы действительно хотите восстановить файл?')) {
    entityStore.deleteFileRestore(fileId).then(() => {
    });
  }
}

const setChatScroll = () => {
  let el = document.getElementById('messages')

  if (el) {
    el.scrollTop = el.scrollHeight
  }
}

const setAvailableStatusList = () => {
  let availableStatusList = changeStatusList.value[entityStore.entity.type][entityStore.entity.status]

  if (typeof availableStatusList === 'undefined') {
    availableStatusList = []
  }

  statusList.value = statusListAll.value.filter(status => availableStatusList.includes(status.id) || status.id === entityStore.entity.status)
}

const canEditAttribute = (attribute) => {
  if (!entityStore.entity.id) {
    return true
  }

  return entityStore.entity.permissions.edit[attribute];
}

const chooseDraftDevelopers = (option) => {
  if (option === -1) {
    entityStore.entity.draft_developers = filteredDevelopers.value.map(item => item.id).filter(item => item !== -1)
  }
}

const filterDraftDevelopersByType = (developersTypeIds) => {
  filteredDevelopers.value = developers.value.filter((item) => {
    return item.id === -1 || !developersTypeIds.length || developersTypeIds.includes(item.type_id)
  })

  let filteredIds = filteredDevelopers.value.map(item => item.id)

  entityStore.entity.draft_developers = entityStore.entity.draft_developers.filter(id => filteredIds.includes(id))
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

button.bnt-process-disabled {
  background-color: rgba(0,0,0,.1);
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: not-allowed;
}
</style>
