<template>
  <main class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col justify-center items-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
      <router-link :to="{name: 'Login'}" href="#" class="flex justify-center items-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
        <img src="../../../public/img/logo.png" class="mr-4 h-11" alt="FlowBite Logo">
        <span>Россия</span>
      </router-link>
      <!-- Card -->
      <div class="justify-center items-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-lg 2xl:max:max-w-screen-lg xl:p-0 dark:bg-gray-800">
        <div class="hidden w-2/3 lg:flex">
          <img class="rounded-l-lg" src="../../../public/img/factory-login.jpg" alt="login image">
        </div>
        <div class="p-6 space-y-8 w-full sm:p-8 lg:p-16 lg:py-0">
          <template v-if="authDisabledMessage">
            <h2 class="text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white" v-html="authDisabledMessage"></h2>
          </template>
          <template v-else>
            <h2 class="text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
              {{ $t(header) }}
            </h2>
            <router-view/>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useRoute} from 'vue-router'

const authDisabledMessage = import.meta.env.VITE_AUTH_DISABLED

const route = useRoute();

const header = computed(() => {
  return route.meta.header
});

</script>